import type { TEditorModes } from "components/editorComponents/CodeEditor/EditorConfig";

export interface Props {
  isAIEnabled: boolean;
  mode: TEditorModes;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function EditorFormSignPosting(props: Props) {
  return null;
}
